import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCoverflow, Pagination, Navigation } from "swiper";
import "swiper/swiper.css";
import "swiper/modules/autoplay/autoplay.js";
import "swiper/modules/pagination/pagination.js";
import "swiper/modules/navigation/navigation.js";
import "swiper/modules/effect-coverflow/effect-coverflow.js";
import "../styles/CarouselSliderStyle.scss";

const CarouselSliderComponent = ({ isWhichWindow }) => {
  const swiperContent = (
    <Swiper
      initialSlide={0}
      loop={true}
      loopedSlides={2}
      slidesPerView={1}
      centeredSlides={true}
      autoplay={{
        delay: 3000,
      }}
      grabCursor={true}
      effect="coverflow"
      coverflowEffect={{
        rotate: 0,
        stretch: 180,
        depth: 140,
        modifier: 2,
        slideShadows: false,
      }}
      pagination={{ clickable: true }}
      modules={[Autoplay, EffectCoverflow, Pagination, Navigation]}
      breakpoints={{
        0: {
          slidesPerView: 1,
          navigation: {
            prevEl: ".style-slider-navigation-prev",
            nextEl: ".style-slider-navigation-next",
          },
          effect: "",
          autoplay: false,
        },
        768: {
          slidesPerView: "auto",
          coverflowEffect: {
            rotate: 0,
            stretch: 135,
            depth: 130,
            modifier: 2,
            slideShadows: false,
          },
        },
        1024: {
          slidesPerView: "auto",
          coverflowEffect: {
            rotate: 0,
            stretch: 180,
            depth: 140,
            modifier: 2,
            slideShadows: false,
          },
        },
      }}
      className="style-slider"
      onClick={(swiper) => {
        swiper.slideTo(swiper.clickedIndex);
      }}
    >
      {[...Array(5)].map((_, index) => (
        <SwiperSlide
          className={`style-slider-slide-${index}`}
          key={index}
        ></SwiperSlide>
      ))}
    </Swiper>
  );
  return (
    <>
      {isWhichWindow === 2 ? (
        <div className="style-slider-navigation">
          <div className="style-slider-navigation-prev"></div>
          {swiperContent}
          <div className="style-slider-navigation-next"></div>
        </div>
      ) : (
        swiperContent
      )}
    </>
  );
};

export default CarouselSliderComponent;
