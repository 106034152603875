import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToTopPage = () => {
  const currentPathName = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPathName]);

  return null;
};

export const ScrollToTopFunction = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const ScrollToClassNameFunction = () => {
  document.querySelector("#scrollTarget").scrollIntoView({
    behavior: "smooth",
  });
};
