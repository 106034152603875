import React, { useEffect, useState } from "react";
import {
  NumberMonthToStringMonth,
  getHistoryList,
} from "../functions/HistoryFunction.js";
import { ReactComponent as AboutContentsAsset } from "../assets/AboutPage/AboutContentsAsset.svg";
import { Wrapper } from "@googlemaps/react-wrapper";
import MapComponent from "../components/MapComponent.js";
import "../styles/AboutStyle.scss";

const AboutPage = ({ isWhichWindow }) => {
  const [historyArray, setHistoryArray] = useState([]);
  const [isHistoryArrayLoading, setIsHistoryArrayLoading] = useState(true);

  useEffect(() => {
    getHistoryList(setHistoryArray, "kor").then(() => {
      setIsHistoryArrayLoading(false);
    });
  }, [isHistoryArrayLoading]);

  return (
    <>
      <div className="style-about-title">
        <div className="style-about-title-text">
          <span>About AAVATAR</span>
          <span>
            아바타테라퓨틱스의 미션은 AAV 플랫폼기술을 바탕으로
            {isWhichWindow === 2 ? <br /> : null}
            희귀난치성 치료제 솔루션을
            {isWhichWindow === 2 ? " " : <br />}
            제공하여 인류의 건강 문제 해결에 기여하는 것입니다.
          </span>
        </div>
      </div>

      <div className="style-about-contents">
        {isWhichWindow === 1 || isWhichWindow === 2 ? (
          <>
            <span>미션</span>
            <span>
              아바타테라퓨틱스의 미션은
              <br />
              AAV 플랫폼기술을 바탕으로 희귀난치성 치료제 솔루션을
              {isWhichWindow === 2 ? <br /> : null} 제공하여
              {isWhichWindow === 2 ? " " : <br />}
              인류의 건강 문제 해결에 기여하는 것입니다.
            </span>
          </>
        ) : null}
        <AboutContentsAsset id="style-about-contents-asset" />
        {isWhichWindow === 0 ? (
          <span>
            아바타테라퓨틱스의 미션은
            <br />
            AAV 플랫폼기술을 바탕으로 희귀난치성 치료제 솔루션을 제공하여
            <br />
            인류의 건강 문제 해결에 기여하는 것입니다.
          </span>
        ) : null}
      </div>

      {isWhichWindow === 2 ? (
        <div className="style-about-history">
          <span id="style-about-history-title">기업 연혁</span>
          <div className="style-about-history-background-top"></div>
          <div className="style-about-history-contents">
            {historyArray.map((historyArrayValue) => (
              <div
                key={historyArrayValue.id}
                className="style-about-history-contents-year"
              >
                {historyArrayValue.year}
                <hr />
                <div className="style-about-history-centents-tile">
                  {Array.isArray(historyArrayValue.months)
                    ? historyArrayValue.months.map((monthArrayValue) => (
                        <div
                          key={monthArrayValue.id}
                          className="style-about-history-contents-month"
                        >
                          <span>
                            {NumberMonthToStringMonth(
                              "kor",
                              monthArrayValue.month
                            )}
                            <span>월</span>
                          </span>
                          <div
                            className="style-about-history-contents-month-contents"
                            dangerouslySetInnerHTML={{
                              __html: monthArrayValue.days,
                            }}
                          ></div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            ))}
            <div className="style-about-history-contents-blank"></div>
          </div>
          <div className="style-about-history-background-bottom"></div>
        </div>
      ) : (
        <div className="style-about-history">
          <span id="style-about-history-title">기업 연혁</span>
          <div className="style-about-history-background-top"></div>
          <div className="style-about-history-background">
            <hr className="style-about-history-background-hr" />
            <div className="style-about-history-contents">
              {historyArray.map((historyArrayValue) => (
                <div
                  key={historyArrayValue.id}
                  className="style-about-history-contents-year"
                >
                  <span className="style-about-history-contents-year-title">
                    {historyArrayValue.year}
                  </span>
                  <div className="style-about-history-contents-month">
                    {Array.isArray(historyArrayValue.months)
                      ? historyArrayValue.months.map((monthArrayValue) => (
                          <div
                            key={monthArrayValue.id}
                            className="style-about-history-contents-month-tile"
                          >
                            <div>
                              {NumberMonthToStringMonth(
                                "kor",
                                monthArrayValue.month
                              )}
                              <span>월</span>
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: monthArrayValue.days,
                              }}
                            ></div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              ))}
              <div className="style-about-history-contents-blank"></div>
            </div>
          </div>
          <div className="style-about-history-background-bottom"></div>
        </div>
      )}

      <div className="style-about-contact">
        <span>Contact Us</span>
        <div className="style-about-contact-contents">
          <Wrapper
            apiKey={process.env.REACT_APP_API_KEY}
            className="style-about-contact-map"
          >
            <MapComponent />
          </Wrapper>
          <div className="style-about-contact-info">
            <div className="style-about-contact-address">
              <span>Headquarter</span>
              {isWhichWindow === 0 ? (
                <span>
                  경기도 하남시 미사대로 540, B동 1022호
                  <br />
                  (현대지식산업센터한강미사2차)
                  <br />
                  우편번호 12925
                </span>
              ) : isWhichWindow === 1 ? (
                <span>
                  경기도 하남시 미사대로 540
                  <br />
                  B동 1022호 (현대지식산업센터한강미사2차)
                  <br />
                  우편번호 12925
                </span>
              ) : (
                <>
                  <span>
                    경기도 하남시 미사대로 540
                    <br />
                    B동 1022호
                  </span>
                  <span>우편번호 12925</span>
                </>
              )}
            </div>
            <div className="style-about-contact-address">
              <span>Biohub</span>
              {isWhichWindow === 0 ? (
                <span>
                  서울특별시 동대문구 회기로 117-3
                  <br />
                  서울 바이오허브 연구실험동 203호
                </span>
              ) : isWhichWindow === 1 ? (
                <span>
                  서울특별시 동대문구 회기로 117-3
                  <br />
                  서울 바이오허브 연구실험동 203호
                </span>
              ) : (
                <span>
                  서울특별시 동대문구 회기로 117-3
                  <br />
                  서울 바이오허브 연구실험동 203호
                </span>
              )}
            </div>
            <div className="style-about-contact-email">
              <span>E-mail</span>
              <span>information@aavatartps.com</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
