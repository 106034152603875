import React, { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  AboutRouteName,
  CultureCareerRouteName,
  InvestorMediaRouteName,
  MainRouteName,
  PlatformPipelineRouteName,
} from "../routes/RouteName";
import { ReactComponent as LogoDesktopAsset } from "../assets/LogoHorizontalAsset.svg";
import { ReactComponent as LogoTabletAsset } from "../assets/LogoVerticalAsset.svg";
import { ReactComponent as LogoMobileAsset } from "../assets/LogoColorHorizontalAsset.svg";
import { ReactComponent as NavOpenButtonAsset } from "../assets/Icons/MenuIconAsset.svg";
import { ReactComponent as NavCloseButtonAsset } from "../assets/Icons/RightArrowAsset.svg";
import "../styles/HeaderStyle.scss";
import { ScrollToTopFunction } from "../functions/ScrollFunction";

const HeaderPage = ({ isKorean, setIsKorean, isWhichWindow }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const changeShowMobileMenuValue = () => {
    if (isWhichWindow === 2) {
      setShowMobileMenu(!showMobileMenu);
    }
  };

  let headerLogoContent;
  if (isWhichWindow === 0) {
    headerLogoContent = <LogoDesktopAsset />;
  } else if (isWhichWindow === 1) {
    headerLogoContent = <LogoTabletAsset />;
  } else {
    headerLogoContent = <LogoMobileAsset />;
  }

  const desktopHeaderButtonsContent = (
    <>
      <div className="style-header-centerButton">
        <button
          id={location.pathname.includes("about") ? "centerButton-clicked" : {}}
          onClick={() => {
            navigate(AboutRouteName);
            ScrollToTopFunction();
            changeShowMobileMenuValue();
          }}
        >
          About AAVATAR
        </button>
        <button
          id={
            location.pathname.includes("platform") ? "centerButton-clicked" : {}
          }
          onClick={() => {
            navigate(PlatformPipelineRouteName);
            ScrollToTopFunction();
            changeShowMobileMenuValue();
          }}
        >
          Platform & Pipeline
        </button>
        <button
          id={
            location.pathname.includes("culture") ? "centerButton-clicked" : {}
          }
          onClick={() => {
            navigate(CultureCareerRouteName);
            ScrollToTopFunction();
            changeShowMobileMenuValue();
          }}
        >
          Culture & Career
        </button>
        <button
          id={
            location.pathname.includes("investor") ? "centerButton-clicked" : {}
          }
          onClick={() => {
            navigate(InvestorMediaRouteName);
            ScrollToTopFunction();
            changeShowMobileMenuValue();
          }}
        >
          Investor & Media
        </button>
      </div>
      <div className="style-header-rightButton">
        <button
          onClick={() => {
            setIsKorean(true);
            localStorage.setItem("lang", true);
            changeShowMobileMenuValue();
          }}
          id={isKorean ? "rightButton-clicked" : {}}
        >
          KO
        </button>
        <hr />
        <button
          onClick={() => {
            setIsKorean(false);
            localStorage.setItem("lang", false);
            changeShowMobileMenuValue();
          }}
          id={isKorean ? {} : "rightButton-clicked"}
        >
          EN
        </button>
      </div>
    </>
  );

  return (
    <>
      <header className="style-header">
        <Link
          to={`${MainRouteName}`}
          replace={true}
          className="style-header-logoAsset"
          onClick={() => ScrollToTopFunction()}
        >
          {headerLogoContent}
        </Link>

        {isWhichWindow === 2 ? (
          <div
            className="style-header-nav-window"
            id={showMobileMenu ? null : "nav-hide"}
          >
            <div className="style-header-nav-buttons">
              <div
                className="style-header-nav-close-button"
                onClick={changeShowMobileMenuValue}
              >
                <NavCloseButtonAsset />
              </div>
              {desktopHeaderButtonsContent}
            </div>
          </div>
        ) : (
          desktopHeaderButtonsContent
        )}

        {isWhichWindow === 2 ? (
          <div
            className="style-header-nav-open-button"
            onClick={changeShowMobileMenuValue}
          >
            <NavOpenButtonAsset />
          </div>
        ) : null}
      </header>
    </>
  );
};

export default HeaderPage;
