import {
  readHistoryEngListDocument,
  readHistoryListDocument,
  readMonthEngListDocument,
  readMonthListDocument,
} from "../repositories/HistoryRepository";

export const getHistoryList = async (setHistoryList, lang) => {
  if (lang === "kor") {
    const historyList = await readHistoryListDocument();
    const historyArray = await Promise.all(
      historyList.docs.map(async (doc) => {
        const monthList = await readMonthListDocument(doc.id);
        if (!monthList.empty) {
          const monthArray = monthList.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          return {
            id: doc.id,
            year: doc.data().year,
            months: monthArray,
          };
        } else {
          return {
            id: doc.id,
            year: doc.data().year,
            months: {},
          };
        }
      })
    );
    setHistoryList(historyArray);
  } else {
    const historyList = await readHistoryEngListDocument();
    const historyArray = await Promise.all(
      historyList.docs.map(async (doc) => {
        const monthList = await readMonthEngListDocument(doc.id);
        if (!monthList.empty) {
          const monthArray = monthList.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          return {
            id: doc.id,
            year: doc.data().year,
            months: monthArray,
          };
        } else {
          return {
            id: doc.id,
            year: doc.data().year,
            months: {},
          };
        }
      })
    );
    setHistoryList(historyArray);
  }
};

export const NumberMonthToStringMonth = (lang, numberMonth) => {
  const korMonths = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const engMonths = [
    "Disabled",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return lang === "kor" ? korMonths[numberMonth] : engMonths[numberMonth];
};
