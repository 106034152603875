import React, { useEffect, useState } from "react";
import AppRouter from "./routes/AppRouter";
import { BrowserRouter } from "react-router-dom";
import { ScrollToTopFunction } from "./functions/ScrollFunction";

function App() {
  const DESKTOP = 0;
  const TABLET = 1;
  const MOBILE = 2;
  const [isKorean, setIsKorean] = useState(() => {
    const savedLang = localStorage.getItem("lang");
    return savedLang ? JSON.parse(savedLang) : true;
  });
  const [isWhichWindow, setIsWhichWindow] = useState(DESKTOP);
  const [targetClassName, setTargetClassName] = useState("");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsWhichWindow(MOBILE);
      } else if (window.innerWidth <= 1024) {
        setIsWhichWindow(TABLET);
      } else {
        setIsWhichWindow(DESKTOP);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  useEffect(() => {
    ScrollToTopFunction();
  }, []);

  useEffect(() => {
    const targetElement = document.getElementsByClassName(targetClassName);
    if (targetElement.length > 0) {
      window.scrollTo({
        top: targetElement[0].offsetTop,
        behavior: "smooth",
      });
      setTargetClassName("");
    }
  }, [targetClassName]);

  return (
    <BrowserRouter>
      <div className="App">
        <AppRouter
          isKorean={isKorean}
          setIsKorean={setIsKorean}
          isWhichWindow={isWhichWindow}
          targetClassName={targetClassName}
          setTargetClassName={setTargetClassName}
        />
      </div>
    </BrowserRouter>
  );
}

export default App;
