import {
  readJobOpeningDocument,
  readJobOpeningListDocument,
} from "../repositories/JobOpeningRepository";

export const getJobOpeningList = async (setJopOpeningList) => {
  const jopOpeningList = await readJobOpeningListDocument();
  const jopOpeningArray = jopOpeningList.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  setJopOpeningList(jopOpeningArray);
};

const maxDepartmentTextLength = 9;

export const truncateDepartmentText = (departmentTextData) => {
  const truncatedText =
    departmentTextData.length > maxDepartmentTextLength
      ? departmentTextData.slice(0, maxDepartmentTextLength) + "..."
      : departmentTextData;
  return truncatedText;
};

const maxMobileDepartmentTextLength = 4;

export const truncateMobileDepartmentText = (departmentTextData) => {
  const truncatedText =
    departmentTextData.length > maxMobileDepartmentTextLength
      ? departmentTextData.slice(0, maxMobileDepartmentTextLength) + "..."
      : departmentTextData;
  return truncatedText;
};

const maxBodyTextLength = 23;

export const truncateBodyText = (bodyTextData) => {
  const truncatedText =
    bodyTextData.length > maxBodyTextLength
      ? bodyTextData.slice(0, maxBodyTextLength) + "..."
      : bodyTextData;
  return truncatedText;
};

const maxTabletBodyTextLength = 16;

export const truncateTabletBodyText = (bodyTextData) => {
  const truncatedText =
    bodyTextData.length > maxTabletBodyTextLength
      ? bodyTextData.slice(0, maxTabletBodyTextLength) + "..."
      : bodyTextData;
  return truncatedText;
};

const maxMobileBodyTextLength = 26;

export const truncateMobileBodyText = (bodyTextData) => {
  const truncatedText =
    bodyTextData.length > maxMobileBodyTextLength
      ? bodyTextData.slice(0, maxMobileBodyTextLength) + "..."
      : bodyTextData;
  return truncatedText;
};

/** @returns {string} ex) 2000.01.01 */
const dateFormat = (dateObject) => {
  return `${dateObject.getFullYear()}.${(dateObject.getMonth() + 1)
    .toString()
    .padStart(2, "0")}.${dateObject.getDate().toString().padStart(2, "0")}`;
};

export const truncateViewNumber = (viewNumberData) => {
  if (viewNumberData >= 1000000000) {
    return (viewNumberData / 1000000000).toFixed(0) + "B";
  } else if (viewNumberData >= 1000000) {
    return (viewNumberData / 1000000).toFixed(0) + "M";
  } else if (viewNumberData >= 1000) {
    return (viewNumberData / 1000).toFixed(0) + "k";
  } else {
    return viewNumberData.toString();
  }
};

export const convertTimestampToDate = (timestampData) => {
  const milliseconds =
    timestampData.seconds * 1000 + timestampData.nanoseconds / 1e6;
  const dateObject = new Date(milliseconds);
  const formattedDate = dateFormat(dateObject);
  return formattedDate;
};
