import { readPipelineListDocument } from "../repositories/PipelineRepository";

export const getPipelineList = async (setPipelineList) => {
  const pipelineList = await readPipelineListDocument();
  const pipelineArray = pipelineList.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  setPipelineList(pipelineArray);
};
