import React, { useEffect, useState } from "react";
import "../styles/InvestorMediaStyle.scss";
import { getMediaList } from "../functions/MediaFunction";
import MediaCardComponent from "../components/MediaCardComponent";
import PageNavigationComponent from "../components/PageNavigationComponent";

const InvestorMediaPage = ({ isWhichWindow }) => {
  const [mediaArray, setMediaArray] = useState([]);
  const [isMediaArrayLoading, setIsMediaArrayLoading] = useState(true);
  const [mediaPage, setMediaPage] = useState(1);
  const mediaPageLimit = 4;
  const mediaPageOffset = (mediaPage - 1) * mediaPageLimit;

  useEffect(() => {
    getMediaList(setMediaArray).then(() => {
      setIsMediaArrayLoading(false);
    });
  }, [isMediaArrayLoading]);

  return (
    <>
      <div className="style-investorMedia-title">
        <div className="style-investorMedia-title-text">
          <span>Investor & Media</span>
          <span>
            아바타테라퓨틱스는 유전자치료제 기술의 발전을 목표로 하고있으며
            <br />
            이를 토대로 인류의 건강 행복을 추구하고자 합니다.
          </span>
        </div>
      </div>

      <div className="style-investorMedia-content">
        <span>Investor</span>
        <div className="style-investorMedia-content-asset"></div>
      </div>

      <div className="style-investorMedia-media">
        <span>Media</span>
        <div className="style-investorMedia-media-contents">
          <div className="style-investorMedia-media-contents-board">
            {isWhichWindow === 2
              ? mediaArray
                  .slice((mediaPage - 1) * 3, (mediaPage - 1) * 3 + 3)
                  .map((mediaArrayValue) => (
                    <MediaCardComponent
                      mediaObject={mediaArrayValue}
                      key={mediaArrayValue.id}
                    />
                  ))
              : mediaArray
                  .slice(mediaPageOffset, mediaPageOffset + mediaPageLimit)
                  .map((mediaArrayValue) => (
                    <MediaCardComponent
                      mediaObject={mediaArrayValue}
                      key={mediaArrayValue.id}
                    />
                  ))}
          </div>
          <div className="style-investorMedia-media-contents-navigation">
            {isWhichWindow === 2 ? (
              <PageNavigationComponent
                dataLength={mediaArray.length}
                pageLimit={3}
                page={mediaPage}
                setPage={setMediaPage}
              />
            ) : (
              <PageNavigationComponent
                dataLength={mediaArray.length}
                pageLimit={mediaPageLimit}
                page={mediaPage}
                setPage={setMediaPage}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestorMediaPage;
