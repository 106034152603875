import React, { useState, useEffect } from "react";
import { ReactComponent as CultureStepAsset } from "../../assets/CultureCareerPage/CultureStepEngAsset.svg";
import { ReactComponent as CultureStepMobileAsset } from "../../assets/CultureCareerPage/CultureStepMobileEngAsset.svg";
import "../../styles/engStyles/CultureCareerEngStyle.scss";
import {
  convertTimestampToDate,
  getJobOpeningList,
  truncateBodyText,
  truncateDepartmentText,
  truncateMobileBodyText,
  truncateMobileDepartmentText,
  truncateTabletBodyText,
  truncateViewNumber,
} from "../../functions/JobOpeningFunction";
import PageNavigationComponent from "../../components/PageNavigationComponent";
import { useNavigate } from "react-router-dom";
import { CultureCareerRouteName } from "../../routes/RouteName";
import { updateViewField } from "../../repositories/JobOpeningRepository";
import DOMPurify from "dompurify";

const CultureCareerEngPage = ({ isWhichWindow }) => {
  const navigate = useNavigate();
  const [jobOpeningArray, setJobOpeningArray] = useState([]);
  const [isJobOpeningArrayLoading, setIsJobOpeningArrayLoading] =
    useState(true);
  const [jobOpeningPage, setJobOpeningPage] = useState(1);
  const jobOpeningPageLimit = 5;
  const jobOpeningPageOffset = (jobOpeningPage - 1) * jobOpeningPageLimit;

  useEffect(() => {
    getJobOpeningList(setJobOpeningArray).then(() => {
      setIsJobOpeningArrayLoading(false);
    });
  }, [isJobOpeningArrayLoading]);

  return (
    <>
      <div className="style-cultureCareer-title">
        <div className="style-eng-cultureCareer-title-text">
          <span>Culture & Career</span>
          <span>
            Aavatar respects each member and pursues the{" "}
            {isWhichWindow === 2 ? <br /> : null}happy development of its
            members {isWhichWindow === 2 ? " " : <br />}
            and "maximum {isWhichWindow === 2 ? <br /> : null}efficiency within
            maximum freedom."
          </span>
        </div>
      </div>

      <div className="style-eng-cultureCareer-culture">
        <span>Corporate Culture</span>
        <span>
          Aavatar Therapeutics aims to continuously challenge the
          {isWhichWindow === 2 ? <br /> : " "}development of treatments for rare
          and intractable{isWhichWindow === 0 ? " " : <br />}diseases
          {isWhichWindow === 0 ? <br /> : " "}
          through respect and cooperation among
          {isWhichWindow === 2 ? <br /> : " "}members, based on a spirit of
          creative innovation.
        </span>
      </div>

      <div className="style-eng-cultureCareer-value">
        <div className="style-eng-cultureCareer-value-asset">
          <span>Core Value</span>
        </div>
      </div>

      <div className="style-eng-cultureCareer-step">
        <span>Recruitment Process</span>
        {isWhichWindow === 2 ? (
          <div className="style-eng-cultureCareer-step-asset">
            <CultureStepMobileAsset id="style-eng-cultureCareer-step-asset" />
          </div>
        ) : (
          <CultureStepAsset id="style-eng-cultureCareer-step-asset" />
        )}
        <span>
          ※ Additional interviews or phone interviews may be
          {isWhichWindow === 2 ? <br /> : " "}conducted during the hiring
          process if necessary.
        </span>
      </div>

      <div className="style-eng-cultureCareer-opening">
        <span>Job Announcement</span>
        <div className="style-cultureCareer-opening-contents">
          <div className="style-cultureCareer-opening-contents-board">
            {isWhichWindow === 2 ? null : (
              <div
                className="style-eng-cultureCareer-opening-contents-board-tile"
                id="style-eng-cultureCareer-opening-contents-board-title"
              >
                <div>Department</div>
                <div>Recruitment</div>
                <div>Application Period</div>
                <div>Status</div>
                <div>Views</div>
              </div>
            )}
            {isWhichWindow === 2
              ? jobOpeningArray
                  .slice(
                    (jobOpeningPage - 1) * (jobOpeningPageLimit - 1),
                    (jobOpeningPage - 1) * (jobOpeningPageLimit - 1) +
                      (jobOpeningPageLimit - 1)
                  )
                  .map((jobOpeningValue) => (
                    <div
                      className="style-eng-cultureCareer-opening-contents-board-tile"
                      key={jobOpeningValue.id}
                      onClick={(e) => {
                        e.preventDefault();
                        updateViewField(
                          jobOpeningValue.id,
                          jobOpeningValue.view + 1
                        );
                        navigate(
                          `${CultureCareerRouteName}/${jobOpeningValue.id}`,
                          { state: { data: jobOpeningValue } }
                        );
                      }}
                    >
                      <div>
                        {truncateMobileBodyText(
                          DOMPurify.sanitize(jobOpeningValue.body, {
                            ALLOWED_TAGS: [],
                          })
                        )}
                      </div>
                      <div className="style-eng-cultureCareer-opening-contents-board-tile-sub">
                        <div className="style-eng-cultureCareer-opening-contents-board-tile-sub-left">
                          <div>
                            {truncateMobileDepartmentText(
                              jobOpeningValue.department
                            )}
                          </div>
                          <hr />
                          <div>
                            {convertTimestampToDate(jobOpeningValue.dateStart)}~
                            {convertTimestampToDate(jobOpeningValue.dateEnd)}
                          </div>
                        </div>
                        <div
                          className="style-eng-cultureCareer-opening-contents-board-tile-sub-right"
                          id={
                            jobOpeningValue.state === true
                              ? "style-eng-cultureCareer-opening-contents-board-tile-true"
                              : {}
                          }
                        >
                          {jobOpeningValue.state === true
                            ? "Accepting"
                            : "Closed"}
                        </div>
                      </div>
                    </div>
                  ))
              : jobOpeningArray
                  .slice(
                    jobOpeningPageOffset,
                    jobOpeningPageOffset + jobOpeningPageLimit
                  )
                  .map((jobOpeningValue) => (
                    <div
                      className="style-eng-cultureCareer-opening-contents-board-tile"
                      key={jobOpeningValue.id}
                      onClick={(e) => {
                        e.preventDefault();
                        updateViewField(
                          jobOpeningValue.id,
                          jobOpeningValue.view + 1
                        );
                        navigate(
                          `${CultureCareerRouteName}/${jobOpeningValue.id}`,
                          { state: { data: jobOpeningValue } }
                        );
                      }}
                    >
                      <div>
                        {truncateDepartmentText(jobOpeningValue.department)}
                      </div>
                      <div>
                        {isWhichWindow === 0
                          ? truncateBodyText(
                              DOMPurify.sanitize(jobOpeningValue.body, {
                                ALLOWED_TAGS: [],
                              })
                            )
                          : truncateTabletBodyText(
                              DOMPurify.sanitize(jobOpeningValue.body, {
                                ALLOWED_TAGS: [],
                              })
                            )}
                      </div>
                      <div>
                        {convertTimestampToDate(jobOpeningValue.dateStart)}~
                        {convertTimestampToDate(jobOpeningValue.dateEnd)}
                      </div>
                      <div
                        id={
                          jobOpeningValue.state === true
                            ? "style-eng-cultureCareer-opening-contents-board-tile-true"
                            : {}
                        }
                      >
                        {jobOpeningValue.state === true
                          ? "Accepting"
                          : "Closed"}
                      </div>
                      <div>{truncateViewNumber(jobOpeningValue.view)}</div>
                    </div>
                  ))}
          </div>
          <div className="style-cultureCareer-opening-contents-navigation">
            <PageNavigationComponent
              dataLength={jobOpeningArray.length}
              pageLimit={jobOpeningPageLimit}
              page={jobOpeningPage}
              setPage={setJobOpeningPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CultureCareerEngPage;
