import React, { useEffect, useRef, useState } from "react";
import "../styles/PipelineChartStyle.scss";
import { getPipelineList } from "../functions/PipelineFunction";

const PipelineChartComponent = ({ isWhichWindow }) => {
  const chartRef = useRef(null);
  const [isAnimated, setIsAnimated] = useState(false);
  const [isWhichPipeline, setIsWhichPipeline] = useState(0);
  const [pipelineArray, setPipelineArray] = useState([]);
  const [isPipeArrayLoading, setIsPipeArrayLoading] = useState(true);

  const handleAnimate = () => {
    const chart = chartRef.current;
    if (chart) {
      const chartPosition = chart.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (chartPosition.top < windowHeight && !isAnimated) {
        setIsAnimated(true);
      }
    }
  };

  const amountToStyle = (amount) => {
    if (amount === 0.5) {
      return "style-bar-0_5";
    } else if (amount === 1) {
      return "style-bar-1";
    } else if (amount === 1.5) {
      return "style-bar-1_5";
    } else if (amount === 2) {
      return "style-bar-2";
    } else if (amount === 2.5) {
      return "style-bar-2_5";
    } else if (amount === 3) {
      return "style-bar-3";
    } else {
      return "style-bar-0";
    }
  };

  useEffect(() => {
    getPipelineList(setPipelineArray).then(() => {
      setIsPipeArrayLoading(false);
    });
  }, [isPipeArrayLoading]);

  useEffect(() => {
    window.addEventListener("scroll", handleAnimate);
    return () => {
      window.removeEventListener("scroll", handleAnimate);
    };
  }, []);

  return (
    <>
      {isWhichWindow === 2 ? (
        <div className="style-pipeline-chart" ref={chartRef}>
          <div className="style-pipeline-navigation">
            {pipelineArray.map((pipelineArrayValue, index) => (
              <div
                key={pipelineArrayValue.id}
                id={isWhichPipeline === index ? "style-navigation-select" : ""}
                onClick={() => setIsWhichPipeline(index)}
              >
                {pipelineArrayValue.target}
              </div>
            ))}
          </div>
          <div className="style-pipeline-chart-tile">
            {pipelineArray.map((pipelineArrayValue, index) => {
              if (index === isWhichPipeline) {
                return (
                  <div style={{ width: "100%" }} key={pipelineArrayValue.id}>
                    <div className="style-pipeline-chart-tile-title">
                      <span>Indication</span>
                      <hr />
                      <span>{pipelineArrayValue.indication}</span>
                    </div>
                    <div className="style-pipeline-char-tile-container">
                      <div
                        className="style-pipeline-char-tile-bar"
                        id={
                          isAnimated
                            ? amountToStyle(pipelineArrayValue.amount)
                            : ""
                        }
                      ></div>
                    </div>
                    <div className="style-pipeline-chat-tile-bottom">
                      <div
                        id={
                          0 < pipelineArrayValue.amount
                            ? "style-bottom-select"
                            : ""
                        }
                      >
                        Discovery
                      </div>
                      <div
                        id={
                          1 < pipelineArrayValue.amount
                            ? "style-bottom-select"
                            : ""
                        }
                      >
                        Preclinical
                      </div>
                      <div
                        id={
                          2 < pipelineArrayValue.amount
                            ? "style-bottom-select"
                            : ""
                        }
                      >
                        Clinical
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : (
        <div className="style-pipeline-chart" ref={chartRef}>
          <div
            className="style-pipeline-chart-tile"
            id="style-pipeline-chart-title"
          >
            <div>Target</div>
            <div>Indication</div>
            <div>Discovery</div>
            <div>Pre-clinical</div>
            <div>Clinical</div>
          </div>

          {pipelineArray.map((pipelineArrayValue) => (
            <div
              className="style-pipeline-chart-tile"
              key={pipelineArrayValue.id}
            >
              <div>{pipelineArrayValue.target}</div>
              <div>{pipelineArrayValue.indication}</div>
              <div className="style-pipeline-char-title-container">
                <div
                  className="style-pipeline-char-title-bar"
                  id={
                    isAnimated ? amountToStyle(pipelineArrayValue.amount) : ""
                  }
                ></div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default PipelineChartComponent;
