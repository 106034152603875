export const MainRouteName = `${process.env.PUBLIC_URL}/`;

export const AboutRouteName = `${process.env.PUBLIC_URL}/about`;

export const PlatformPipelineRouteName = `${process.env.PUBLIC_URL}/platformpipeline`;

export const CultureCareerRouteName = `${process.env.PUBLIC_URL}/culturecareer`;

export const JobOpeningRouteName = `${process.env.PUBLIC_URL}/culturecareer/:id`;

export const InvestorMediaRouteName = `${process.env.PUBLIC_URL}/investormedia`;
