import { dbService } from "../fbase";
import { JobOpeningModelConverter } from "../models/JobOpeningModel";

export const readJobOpeningListDocument = async () => {
  const jobOpeningListModel = await dbService
    .collection("Jobs")
    .orderBy("dateUpload", "desc")
    .get();
  return jobOpeningListModel;
};

export const updateViewField = async (docId, newValue) => {
  try {
    const docRef = dbService.collection("Jobs").doc(docId);
    await docRef.update({ view: newValue });
    return true;
  } catch (e) {
    return false;
  }
};
