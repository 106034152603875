import React from "react";
import { useState, useEffect, useRef } from "react";

const MapComponent = () => {
    const [map, setMap] = useState();
    const ref = useRef();

    useEffect(() => {
        const newMap = new window.google.maps.Map(ref.current, {
            center : { lat: 37.5576285, lng: 127.2055429 },
            zoom : 18,
        });
        setMap(newMap);
    }, [])

    return (
        <div className="style-about-contact-map" ref={ref}></div>
    )
}

export default MapComponent;