import { dbService } from "../fbase";

export const readHistoryListDocument = async () => {
  const historyListModel = await dbService
    .collection("Histories")
    .orderBy("year", "desc")
    .get();

  return historyListModel;
};

export const readHistoryEngListDocument = async () => {
  const historyListModel = await dbService
    .collection("HistoriesEng")
    .orderBy("year", "desc")
    .get();

  return historyListModel;
};

export const readMonthListDocument = async (historyDocId) => {
  const monthListModel = await dbService
    .collection("Histories")
    .doc(historyDocId)
    .collection("Months")
    .orderBy("month", "desc")
    .get();

  return monthListModel;
};

export const readMonthEngListDocument = async (historyDocId) => {
  const monthListModel = await dbService
    .collection("HistoriesEng")
    .doc(historyDocId)
    .collection("Months")
    .orderBy("month", "desc")
    .get();

  return monthListModel;
};
