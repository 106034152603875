import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  MainRouteName,
  AboutRouteName,
  PlatformPipelineRouteName,
  CultureCareerRouteName,
  InvestorMediaRouteName,
  JobOpeningRouteName,
} from "./RouteName.js";
import HeaderPage from "../pages/HeaderPage.js";
import MainPage from "../pages/MainPage.js";
import AboutPage from "../pages/AboutPage.js";
import PlatformPipelinePage from "../pages/PlatformPipelinePage.js";
import CultureCareerPage from "../pages/CultureCareerPage.js";
import InvestorMediaPage from "../pages/InvestorMediaPage.js";
import FooterPage from "../pages/FooterPage.js";
import JobOpeningPage from "../pages/JobOpeningPage.js";
import MainEngPage from "../pages/engPages/MainEngPage.js";
import AboutEngPage from "../pages/engPages/AboutEngPage.js";
import PlatformPipelineEngPage from "../pages/engPages/PlatformPipelineEngPage.js";
import CultureCareerEngPage from "../pages/engPages/CultureCareerEngPage.js";
import JobOpeningEngPage from "../pages/engPages/JobOpeningEngPage.js";
import InvestorMediaEngPage from "../pages/engPages/InvestorMediaEngPage.js";
import FooterEngPage from "../pages/engPages/FooterEngPage.js";

const AppRouter = ({
  isKorean,
  setIsKorean,
  isWhichWindow,
  targetClassName,
  setTargetClassName,
}) => {
  return (
    <>
      <HeaderPage
        isKorean={isKorean}
        setIsKorean={setIsKorean}
        isWhichWindow={isWhichWindow}
        targetClassName={targetClassName}
        setTargetClassName={setTargetClassName}
      />
      <Routes>
        <Route
          path={MainRouteName}
          element={
            isKorean ? (
              <MainPage isWhichWindow={isWhichWindow} />
            ) : (
              <MainEngPage isWhichWindow={isWhichWindow} />
            )
          }
        />
        <Route
          path={AboutRouteName}
          element={
            isKorean ? (
              <AboutPage isWhichWindow={isWhichWindow} />
            ) : (
              <AboutEngPage isWhichWindow={isWhichWindow} />
            )
          }
        />
        <Route
          path={PlatformPipelineRouteName}
          element={
            isKorean ? (
              <PlatformPipelinePage isWhichWindow={isWhichWindow} />
            ) : (
              <PlatformPipelineEngPage isWhichWindow={isWhichWindow} />
            )
          }
        />
        <Route
          path={CultureCareerRouteName}
          element={
            isKorean ? (
              <CultureCareerPage isWhichWindow={isWhichWindow} />
            ) : (
              <CultureCareerEngPage isWhichWindow={isWhichWindow} />
            )
          }
        />
        <Route
          path={JobOpeningRouteName}
          element={
            isKorean ? (
              <JobOpeningPage isWhichWindow={isWhichWindow} />
            ) : (
              <JobOpeningEngPage isWhichWindow={isWhichWindow} />
            )
          }
        />
        <Route
          path={InvestorMediaRouteName}
          element={
            isKorean ? (
              <InvestorMediaPage isWhichWindow={isWhichWindow} />
            ) : (
              <InvestorMediaEngPage isWhichWindow={isWhichWindow} />
            )
          }
        />
        <Route path="*" />
      </Routes>
      {isKorean ? (
        <FooterPage
          isWhichWindow={isWhichWindow}
          setTargetClassName={setTargetClassName}
        />
      ) : (
        <FooterEngPage
          isWhichWindow={isWhichWindow}
          setTargetClassName={setTargetClassName}
        />
      )}
    </>
  );
};

export default AppRouter;
