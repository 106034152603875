import React, { useState, useEffect } from "react";
import { ReactComponent as CultureStepAsset } from "../assets/CultureCareerPage/CultureStepAsset.svg";
import { ReactComponent as CultureStepMobileAsset } from "../assets/CultureCareerPage/CultureStepMobileAsset.svg";
import "../styles/CultureCareerStyle.scss";
import {
  convertTimestampToDate,
  getJobOpeningList,
  truncateBodyText,
  truncateDepartmentText,
  truncateMobileBodyText,
  truncateMobileDepartmentText,
  truncateTabletBodyText,
  truncateViewNumber,
} from "../functions/JobOpeningFunction";
import PageNavigationComponent from "../components/PageNavigationComponent";
import { useNavigate } from "react-router-dom";
import { CultureCareerRouteName } from "../routes/RouteName";
import { updateViewField } from "../repositories/JobOpeningRepository";
import DOMPurify from "dompurify";

const CultureCareerPage = ({ isWhichWindow }) => {
  const navigate = useNavigate();
  const [jobOpeningArray, setJobOpeningArray] = useState([]);
  const [isJobOpeningArrayLoading, setIsJobOpeningArrayLoading] =
    useState(true);
  const [jobOpeningPage, setJobOpeningPage] = useState(1);
  const jobOpeningPageLimit = 5;
  const jobOpeningPageOffset = (jobOpeningPage - 1) * jobOpeningPageLimit;

  useEffect(() => {
    getJobOpeningList(setJobOpeningArray).then(() => {
      setIsJobOpeningArrayLoading(false);
    });
  }, [isJobOpeningArrayLoading]);

  return (
    <>
      <div className="style-cultureCareer-title">
        <div className="style-cultureCareer-title-text">
          <span>Culture & Career</span>
          <span>
            아바타테라퓨틱스는 구성원 각자를 존중하며 구성원의 행복한{" "}
            {isWhichWindow === 2 ? <br /> : null} 발전과
            {isWhichWindow === 2 ? " " : <br />}
            '최대 자유 속에 최고 효율'을 추구합니다.
          </span>
        </div>
      </div>

      <div className="style-cultureCareer-culture">
        <span>기업 문화</span>
        <span>
          아바타테라퓨틱스는 창의적인 혁신 정신을 바탕으로
          <br />
          구성원들간의 존중과 협력을 통해 희귀 난치성 질환 치료제 개발에
          {isWhichWindow === 2 ? <br /> : null} 끊임없이 도전하려고 합니다.
        </span>
      </div>

      <div className="style-cultureCareer-value">
        <div className="style-cultureCareer-value-asset">
          <span>핵심가치</span>
        </div>
      </div>

      <div className="style-cultureCareer-step">
        <span>채용 절차</span>
        {isWhichWindow === 2 ? (
          <div className="style-cultureCareer-step-asset">
            <CultureStepMobileAsset id="style-cultureCareer-step-asset" />
          </div>
        ) : (
          <CultureStepAsset id="style-cultureCareer-step-asset" />
        )}
        <span>
          ※ 필요 시 채용과정 중 {isWhichWindow === 2 ? <br /> : null}추가적인
          면접, 전화 인터뷰 등이 진행될 수 있습니다.
        </span>
      </div>

      <div className="style-cultureCareer-opening">
        <span>채용 공고</span>
        <div className="style-cultureCareer-opening-contents">
          <div className="style-cultureCareer-opening-contents-board">
            {isWhichWindow === 2 ? null : (
              <div
                className="style-cultureCareer-opening-contents-board-tile"
                id="style-cultureCareer-opening-contents-board-title"
              >
                <div>지원부서</div>
                <div>모집내용</div>
                <div>접수마감</div>
                <div>진행상태</div>
                <div>조회수</div>
              </div>
            )}
            {isWhichWindow === 2
              ? jobOpeningArray
                  .slice(
                    (jobOpeningPage - 1) * (jobOpeningPageLimit - 1),
                    (jobOpeningPage - 1) * (jobOpeningPageLimit - 1) +
                      (jobOpeningPageLimit - 1)
                  )
                  .map((jobOpeningValue) => (
                    <div
                      className="style-cultureCareer-opening-contents-board-tile"
                      key={jobOpeningValue.id}
                      onClick={(e) => {
                        e.preventDefault();
                        updateViewField(
                          jobOpeningValue.id,
                          jobOpeningValue.view + 1
                        );
                        navigate(
                          `${CultureCareerRouteName}/${jobOpeningValue.id}`,
                          { state: { data: jobOpeningValue } }
                        );
                      }}
                    >
                      <div>
                        {truncateMobileBodyText(
                          DOMPurify.sanitize(jobOpeningValue.body, {
                            ALLOWED_TAGS: [],
                          })
                        )}
                      </div>
                      <div className="style-cultureCareer-opening-contents-board-tile-sub">
                        <div className="style-cultureCareer-opening-contents-board-tile-sub-left">
                          <div>
                            {truncateMobileDepartmentText(
                              jobOpeningValue.department
                            )}
                          </div>
                          <hr />
                          <div>
                            {convertTimestampToDate(jobOpeningValue.dateStart)}~
                            {convertTimestampToDate(jobOpeningValue.dateEnd)}
                          </div>
                        </div>
                        <div
                          className="style-cultureCareer-opening-contents-board-tile-sub-right"
                          id={
                            jobOpeningValue.state === true
                              ? "style-cultureCareer-opening-contents-board-tile-true"
                              : {}
                          }
                        >
                          {jobOpeningValue.state === true ? "접수 중" : "종료"}
                        </div>
                      </div>
                    </div>
                  ))
              : jobOpeningArray
                  .slice(
                    jobOpeningPageOffset,
                    jobOpeningPageOffset + jobOpeningPageLimit
                  )
                  .map((jobOpeningValue) => (
                    <div
                      className="style-cultureCareer-opening-contents-board-tile"
                      key={jobOpeningValue.id}
                      onClick={(e) => {
                        e.preventDefault();
                        updateViewField(
                          jobOpeningValue.id,
                          jobOpeningValue.view + 1
                        );
                        navigate(
                          `${CultureCareerRouteName}/${jobOpeningValue.id}`,
                          { state: { data: jobOpeningValue } }
                        );
                      }}
                    >
                      <div>
                        {truncateDepartmentText(jobOpeningValue.department)}
                      </div>
                      <div>
                        {isWhichWindow === 0
                          ? truncateBodyText(
                              DOMPurify.sanitize(jobOpeningValue.body, {
                                ALLOWED_TAGS: [],
                              })
                            )
                          : truncateTabletBodyText(
                              DOMPurify.sanitize(jobOpeningValue.body, {
                                ALLOWED_TAGS: [],
                              })
                            )}
                      </div>
                      <div>
                        {convertTimestampToDate(jobOpeningValue.dateStart)}~
                        {convertTimestampToDate(jobOpeningValue.dateEnd)}
                      </div>
                      <div
                        id={
                          jobOpeningValue.state === true
                            ? "style-cultureCareer-opening-contents-board-tile-true"
                            : {}
                        }
                      >
                        {jobOpeningValue.state === true ? "접수 중" : "종료"}
                      </div>
                      <div>{truncateViewNumber(jobOpeningValue.view)}</div>
                    </div>
                  ))}
          </div>
          <div className="style-cultureCareer-opening-contents-navigation">
            <PageNavigationComponent
              dataLength={jobOpeningArray.length}
              pageLimit={jobOpeningPageLimit}
              page={jobOpeningPage}
              setPage={setJobOpeningPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CultureCareerPage;
