import React, { useEffect, useState } from "react";
import { ReactComponent as PlatformContent1Asset } from "../../assets/PlatformPipelinePage/PlatformContent1Asset.svg";
import { ReactComponent as PlatformContent1TabletAsset } from "../../assets/PlatformPipelinePage/PlatformContent1TabletAsset.svg";
import { ReactComponent as PlatformContent1MobileAsset } from "../../assets/PlatformPipelinePage/PlatformContent1MobileAsset.svg";
import PipelineChartComponent from "../../components/PipelineChartComponent";
import "../../styles/engStyles/PlatformPipelineEngStyle.scss";
import PageNavigationComponent from "../../components/PageNavigationComponent";
import { getPublicationList } from "../../functions/PublicationFunction";

const PlatformPipelineEngPage = ({ isWhichWindow }) => {
  const [isWhichCMC, setIsWhichCMC] = useState(0);
  const [publicationArray, setPublicationArray] = useState([]);
  const [isPublicationArrayLoading, setIsPublicationArrayLoading] =
    useState(true);
  const [publicationPage, setPublicationPage] = useState(1);
  const publicationPageLimit = 3;
  const publicationPageOffset = (publicationPage - 1) * publicationPageLimit;

  useEffect(() => {
    getPublicationList(setPublicationArray).then(() => {
      setIsPublicationArrayLoading(false);
    });
  }, [isPublicationArrayLoading]);

  return (
    <>
      <div className="style-platformPipeline-title">
        <div className="style-platformPipeline-title-text">
          <span>Platform & Pipeline</span>
        </div>
      </div>

      <div className="style-eng-platformPipeline-aavplatform">
        <span>AAV Platform</span>
        {isWhichWindow === 2 ? (
          <span>
            Aavatar Therapeutics' <br />
            <b>AAV engineering platform</b> can efficiently
            <br />
            create new AAVs based on machine learning. <br />
            <br />
            These <b>new AAVs</b> are capable of high target delivery
            <br /> and penetration of the Blood-Brain-Barrier(BBB)
            <br /> even at low doses, solving the problems of <br />
            <b>traditional gene delivery vectors.</b>
          </span>
        ) : isWhichWindow === 1 ? (
          <span>
            Aavatar Therapeutics' AAV engineering platform can efficiently
            create new AAVs <br />
            based on machine learning. These new AAVs are capable of high target
            delivery and <br />
            penetration of the Blood-Brain-Barrier(BBB) even at low doses,
            solving the problems <br />
            of traditional gene delivery vectors.
          </span>
        ) : (
          <span>
            Aavatar Therapeutics' AAV engineering platform can efficiently
            create new AAVs based <br />
            on machine learning. These new AAVs are capable of high target
            delivery and penetration <br />
            of the Blood-Brain-Barrier(BBB) even at low doses, solving the
            problems of traditional <br />
            gene delivery vectors.
          </span>
        )}
        {isWhichWindow === 0 ? (
          <PlatformContent1Asset id="style-platformPipeline-content1" />
        ) : isWhichWindow === 1 ? (
          <PlatformContent1TabletAsset id="style-platformPipeline-content1" />
        ) : (
          <PlatformContent1MobileAsset id="style-eng-platformPipeline-content1" />
        )}

        <div id="style-eng-platformPipeline-content2">
          <div id="style-platformPipeline-content2-background"></div>
          <div>Less but Better</div>
          <div>
            AAV Engineering, Common Disease, Protein Engineering,
            <br />
            Rare Disease, Genome Editing
          </div>
          <div id="style-eng-platformPipeline-content2-asset"></div>
          {isWhichWindow === 2 ? <span>Pipeline</span> : null}
        </div>
      </div>

      <div className="style-platformPipeline-pipeline">
        {isWhichWindow !== 2 ? <span>Pipeline</span> : null}
        <PipelineChartComponent isWhichWindow={isWhichWindow} />
      </div>

      <div className="style-eng-platformPipeline-cmc">
        <span>CMC</span>
        {isWhichWindow === 2 ? (
          <span>
            Aavatar Therapeutics' CMC division
            <br /> can produce AAVs at low cost, high purity, and <br />
            high yield based on Design of Experiments (DOE). <br />
            Additionally, CMC division has the ability to provide rapid <br />
            research feedback through in-house production <br />
            internalization.
          </span>
        ) : isWhichWindow === 1 ? (
          <span>
            Aavatar Therapeutics' CMC division can produce AAVs <br />
            at low cost, high purity, and high yield based on Design of
            Experiments (DOE). <br />
            Additionally, CMC division has the ability to provide rapid research
            feedback <br />
            through in-house production internalization.
          </span>
        ) : (
          <span>
            Aavatar Therapeutics' CMC division can produce AAVs at low cost,
            high purity, <br />
            and high yield based on Design of Experiments (DOE). Additionally,
            CMC division has the <br />
            ability to provide rapid research feedback through in-house
            production internalization.
          </span>
        )}

        {isWhichWindow === 2 ? (
          <div className="style-platformPipeline-cmc-content">
            <div className="style-platformPipeline-cmc-navigation">
              <div
                id={isWhichCMC === 0 ? "style-cmc-navigation-select" : ""}
                onClick={() => setIsWhichCMC(0)}
              >
                Upstream
              </div>
              <div
                id={isWhichCMC === 1 ? "style-cmc-navigation-select" : ""}
                onClick={() => setIsWhichCMC(1)}
              >
                Downstream
              </div>
            </div>
            <div
              className={
                isWhichCMC === 0
                  ? "style-platformPipeline-cmc-content-upstream"
                  : "style-platformPipeline-cmc-content-downstream"
              }
            ></div>
          </div>
        ) : (
          <div className="style-platformPipeline-cmc-content">
            <div className="style-platformPipeline-cmc-content-upstream">
              <div>Upstream</div>
              <div></div>
            </div>
            <hr className="style-platformPipeline-cmc-content-hr" />
            <div className="style-platformPipeline-cmc-content-downstream">
              <div>Downstream</div>
              <div></div>
            </div>
          </div>
        )}
      </div>

      <div className="style-platformPipeline-publication">
        <span>Publication</span>
        {isWhichWindow === 2 ? (
          <div className="style-platformPipeline-publication-board">
            {publicationArray
              .slice(
                publicationPageOffset,
                publicationPageOffset + publicationPageLimit
              )
              .map((publicationArrayValue) => (
                <div
                  className="style-platformPipeline-publication-board-tile"
                  key={publicationArrayValue.id}
                >
                  <div className="style-platformPipeline-publication-board-tile-title">
                    <div>
                      <span>
                        {publicationArray.indexOf(publicationArrayValue) + 1}
                      </span>
                    </div>
                    <div>{publicationArrayValue.year}</div>
                  </div>
                  <div className="style-platformPipeline-publication-board-tile-body">
                    {publicationArrayValue.title.replace(/<br\/>/g, " ")}
                  </div>
                  <div className="style-eng-platformPipeline-publication-board-tile-tag">
                    <div>{publicationArrayValue.journal}</div>
                    <hr />
                    <div>Database: {publicationArrayValue.database}</div>
                  </div>
                  <div className="style-platformPipeline-publication-board-tile-bottom">
                    Impact factor: {publicationArrayValue.impactFactor}
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="style-platformPipeline-publication-board">
            <div
              className="style-platformPipeline-publication-board-tile"
              id="style-eng-platformPipeline-publication-board-title"
            >
              <div>Number</div>
              <div>Year</div>
              <div>Title</div>
              <div>Journal</div>
              <div>Database</div>
              <div>Impact factor</div>
            </div>
            {publicationArray
              .slice(
                publicationPageOffset,
                publicationPageOffset + publicationPageLimit
              )
              .map((publicationArrayValue) => (
                <div
                  className="style-platformPipeline-publication-board-tile"
                  key={publicationArrayValue.id}
                >
                  <div>
                    {publicationArray.indexOf(publicationArrayValue) + 1}
                  </div>
                  <div>{publicationArrayValue.year}</div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: publicationArrayValue.title,
                    }}
                  ></div>
                  <div>{publicationArrayValue.journal}</div>
                  <div>{publicationArrayValue.database}</div>
                  <div>{publicationArrayValue.impactFactor}</div>
                </div>
              ))}
          </div>
        )}
      </div>
      <div className="style-platformPipeline-publication-navigation">
        <PageNavigationComponent
          dataLength={publicationArray.length}
          pageLimit={publicationPageLimit}
          page={publicationPage}
          setPage={setPublicationPage}
        />
      </div>
    </>
  );
};

export default PlatformPipelineEngPage;
