import React from "react";
import "../styles/MediaCardStyle.scss";
import {
  truncateMediaLinkText,
  truncateMediaPublisherText,
  truncateMediaTitleText,
} from "../functions/MediaFunction";

const MediaCardComponent = ({ mediaObject }) => {
  return (
    <a
      href={mediaObject.mediaLink}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
      className="style-media-a"
    >
      <div className="style-mediaCard" key={mediaObject.id}>
        <div>{truncateMediaTitleText(mediaObject.mediaTitle)}</div>
        <hr />
        <div>{truncateMediaPublisherText(mediaObject.mediaPublisher)}</div>
        <div>{truncateMediaLinkText(mediaObject.mediaLink)}</div>
        <div
          className="style-mediaCard-background"
          style={{
            backgroundImage: `url(${mediaObject.mediaImage})`,
          }}
        ></div>
      </div>
    </a>
  );
};

export default MediaCardComponent;
