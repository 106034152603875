import React, { useEffect, useState } from "react";
import "../styles/JobOpeningStyle.scss";
import { useLocation } from "react-router-dom";
import { convertTimestampToDate } from "../functions/JobOpeningFunction";

const JobOpeningPage = ({ isWhichWindow }) => {
  const location = useLocation();
  const [jobData] = useState(location.state.data);
  const [jobDepartment, setJobDepartment] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobBody, setJobBody] = useState("");
  const [jobDateStart, setJobDateStart] = useState("");
  const [jobDateEnd, setJobDateEnd] = useState("");
  const [jobState, setJobState] = useState("");
  const [isJobLoading, setIsJobLoading] = useState(true);

  useEffect(() => {
    if (jobData !== null) {
      setJobDepartment(jobData.department);
      setJobTitle(jobData.title);
      setJobBody(jobData.body);
      setJobDateStart(convertTimestampToDate(jobData.dateStart));
      setJobDateEnd(convertTimestampToDate(jobData.dateEnd));
      setJobState(jobData.state);
      setIsJobLoading(false);
    }
  }, [isJobLoading, jobData]);

  return (
    <>
      {isJobLoading ? (
        <>Loading</>
      ) : (
        <>
          <div className="style-cultureCareer-title">
            <div className="style-cultureCareer-title-text">
              <span>Culture & Career</span>
              <span>
                아바타테라퓨틱스는 구성원 각자를 존중하며 구성원의 행복한{" "}
                {isWhichWindow === 2 ? <br /> : null} 발전과
                {isWhichWindow === 2 ? " " : <br />}
                '최대 자유 속에 최고 효율'을 추구합니다.
              </span>
            </div>
          </div>

          <div className="style-job">
            <div className="style-job-title">
              <div>채용공고</div>
              <div>{jobTitle}</div>
            </div>

            <div className="style-job-subTitle">
              <div className="style-job-subTitle-department">
                <span>지원부서</span>
                <span>{jobDepartment}</span>
              </div>
              <div className="style-job-subTitle-date">
                <span>접수기간</span>
                <span>
                  {jobDateStart}~{jobDateEnd}
                </span>
              </div>
              <div className="style-job-subTitle-state">
                <span>진행상태</span>
                <span>{jobState ? "접수 중" : "종료"}</span>
              </div>
            </div>
            <hr className="style-job-divider" />
            <div
              dangerouslySetInnerHTML={{
                __html: jobBody,
              }}
            ></div>
          </div>
        </>
      )}
    </>
  );
};

export default JobOpeningPage;
