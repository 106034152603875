import { readPublicationListDocument } from "../repositories/PublicationRepository";

export const getPublicationList = async (setPublicationList) => {
  const publicationList = await readPublicationListDocument();
  const publicationArray = publicationList.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  setPublicationList(publicationArray);
};
