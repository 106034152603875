import React from "react";
import { ReactComponent as PageNavigationLeftArrowAsser } from "../assets/Icons/PageNavigationLeftArrowAsset.svg";
import { ReactComponent as PageNavigationRightArrowAsser } from "../assets/Icons/PageNavigationRightArrowAsset.svg";

import "../styles/PageNavigationStyle.scss";

const PageNavigationComponent = ({ dataLength, pageLimit, page, setPage }) => {
  const totalPage = Math.ceil(dataLength / pageLimit);

  return (
    <>
      {totalPage === 0 ? (
        <div className="style-pageNavigation">
          <button className="style-pageNavigation-LftBtn" disabled>
            <PageNavigationLeftArrowAsser className="style-pageNavigation-btn-asset" />
          </button>
          <div className="style-pageNavigation-NumBtns">
            <button
              className="style-pageNavigation-NumBtn"
              key={1}
              aria-current={1 === 1 && "activePage"}
            >
              1
            </button>
          </div>
          <button className="style-pageNavigation-RhtBtn" disabled>
            <PageNavigationRightArrowAsser className="style-pageNavigation-btn-asset" />
          </button>
        </div>
      ) : (
        <div className="style-pageNavigation">
          <button
            className="style-pageNavigation-LftBtn"
            onClick={() => setPage(page - 1)}
            disabled={page === 1}
          >
            <PageNavigationLeftArrowAsser className="style-pageNavigation-btn-asset" />
          </button>
          <div className="style-pageNavigation-NumBtns">
            {Array(totalPage)
              .fill()
              .map((_, index) => (
                <button
                  className="style-pageNavigation-NumBtn"
                  key={index + 1}
                  onClick={() => setPage(index + 1)}
                  aria-current={page === index + 1 && "activePage"}
                >
                  {index + 1}
                </button>
              ))}
          </div>
          <button
            className="style-pageNavigation-RhtBtn"
            onClick={() => setPage(page + 1)}
            disabled={page === totalPage}
          >
            <PageNavigationRightArrowAsser className="style-pageNavigation-btn-asset" />
          </button>
        </div>
      )}
    </>
  );
};

export default PageNavigationComponent;
