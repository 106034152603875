import React, { useState } from "react";
import "../styles/FooterStyle.scss";
import {
  AboutRouteName,
  CultureCareerRouteName,
  InvestorMediaRouteName,
  PlatformPipelineRouteName,
} from "../routes/RouteName";
import { ReactComponent as DownArrowAsset } from "../assets/Icons/DownArrowAsset.svg";
import { useNavigate } from "react-router-dom";

const FooterPage = ({ isWhichWindow, setTargetClassName }) => {
  const navigate = useNavigate();
  const [isShowAbout, setIsShowAbout] = useState(false);
  const [isShowPlatform, setIsShowPlatform] = useState(false);
  const [isShowCulture, setIsShowCulture] = useState(false);
  const [isShowInvestor, setIsShowInvestor] = useState(false);

  function setAllShowStateToFalse() {
    setIsShowAbout(false);
    setIsShowPlatform(false);
    setIsShowCulture(false);
    setIsShowInvestor(false);
  }

  return (
    <>
      <footer className="style-footer">
        <div className="style-footer-contents">
          <div className="style-footer-contents-title">
            <span>(주)아바타테라퓨틱스</span>
            <span>AAVATAR Therapeutics Inc.</span>
          </div>
          <div className="style-footer-contents-bottom">
            <div className="style-footer-contents-left">
              <div className="style-footer-contents-body">
                <span id="title">CEO</span>
                <span>
                  <span>조 승 희</span>
                  <span>Seunghee Cho</span>
                </span>
              </div>
              {isWhichWindow === 2 ? (
                <div
                  className="style-footer-contents-body"
                  id="style-footer-contents-body-last"
                >
                  <span id="title">E-mail</span>
                  <span>
                    <span>information@aavatartps.com</span>
                  </span>
                </div>
              ) : (
                <>
                  <div
                    className="style-footer-contents-body"
                    id="style-footer-contents-body-address"
                  >
                    <span id="title">HQ</span>
                    <span>
                      <span>
                        경기도 하남시 미사대로 540, B동
                        1023호(현대지식산업센터한강미사2차)
                      </span>
                      <div className="style-footer-contents-body-blank"></div>
                      <span>
                        B-1023 540, Misa-daero, Hanam-si, Gyeonggi-do, Republic
                        of Korea
                      </span>
                    </span>
                  </div>
                  <div
                    className="style-footer-contents-body"
                    id="style-footer-contents-body-address"
                  >
                    <span id="title">BioHub</span>
                    <span>
                      <span>
                        서울특별시 동대문구 회기로 117-3 서울 바이오허브
                        연구실험동 203호
                      </span>
                      <div className="style-footer-contents-body-blank"></div>
                      <span>
                        117-3, Hoegi-ro, Dongdaemun-gu, Seoul, Republic of Korea
                      </span>
                    </span>
                  </div>
                </>
              )}
            </div>
            <div className="style-footer-contents-right">
              {isWhichWindow === 2 ? (
                <>
                  <div
                    className="style-footer-contents-body"
                    id="style-footer-contents-body-address"
                  >
                    <span id="title">HQ</span>
                    <span>
                      <span>
                        경기도 하남시 미사대로 540, B동
                        1023호(현대지식산업센터한강미사2차)
                      </span>
                      <div className="style-footer-contents-body-blank"></div>
                      <span>
                        B-1023 540, Misa-daero, Hanam-si, Gyeonggi-do, Republic
                        of Korea
                      </span>
                    </span>
                  </div>
                  <div
                    className="style-footer-contents-body"
                    id="style-footer-contents-body-address"
                  >
                    <span id="title">BioHub</span>
                    <span>
                      <span>
                        서울특별시 동대문구 회기로 117-3 서울 바이오허브
                        연구실험동 203호
                      </span>
                      <div className="style-footer-contents-body-blank"></div>
                      <span>
                        117-3, Hoegi-ro, Dongdaemun-gu, Seoul, Republic of Korea
                      </span>
                    </span>
                  </div>
                </>
              ) : (
                <div
                  className="style-footer-contents-body"
                  id="style-footer-contents-body-last"
                >
                  <span id="title">E-mail</span>
                  <span>
                    <span>information@aavatartps.com</span>
                  </span>
                </div>
              )}
              <div className="style-footer-contents-footer">
                https://www.aavatartps.com
                <br />
                &copy; AAVATAR Therapeutics Inc. All rights reserved.
              </div>
            </div>
          </div>
        </div>
        <div className="style-footer-contents-category">
          <div className="style-footer-contents-category-tile">
            {isWhichWindow === 2 ? (
              <div
                className="style-footer-contents-category-tile-drop"
                onClick={() => setIsShowAbout(!isShowAbout)}
              >
                <div>About</div>
                <DownArrowAsset
                  className="style-footer-contents-category-tile-asset"
                  style={{
                    transform: isShowAbout ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                />
              </div>
            ) : (
              <div>About</div>
            )}
            <div
              className="style-footer-contents-category-tile-button"
              id={isShowAbout ? "style-footer-category-tile-button-active" : ""}
              style={{ height: isShowAbout ? "144px" : null }}
            >
              <button
                onClick={(e) => {
                  e.isPropagationStopped();
                  navigate(AboutRouteName);
                  setTargetClassName("style-about-contents");
                  setAllShowStateToFalse();
                }}
              >
                Who we are &gt;
              </button>
              <button
                onClick={(e) => {
                  e.isPropagationStopped();
                  navigate(AboutRouteName);
                  setTargetClassName("style-about-history");
                  setAllShowStateToFalse();
                }}
              >
                History &gt;
              </button>
              <button
                onClick={(e) => {
                  e.isPropagationStopped();
                  navigate(AboutRouteName);
                  setTargetClassName("style-about-contact");
                  setAllShowStateToFalse();
                }}
              >
                Contact &gt;
              </button>
            </div>
          </div>
          <div className="style-footer-contents-category-tile">
            {isWhichWindow === 2 ? (
              <div
                className="style-footer-contents-category-tile-drop"
                onClick={() => setIsShowPlatform(!isShowPlatform)}
              >
                <div>Platform & Pipeline</div>
                <DownArrowAsset
                  className="style-footer-contents-category-tile-asset"
                  style={{
                    transform: isShowPlatform
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                />
              </div>
            ) : (
              <div>Platform & Pipeline</div>
            )}
            <div
              className="style-footer-contents-category-tile-button"
              id={
                isShowPlatform ? "style-footer-category-tile-button-active" : ""
              }
              style={{ height: isShowPlatform ? "144px" : null }}
            >
              <button
                onClick={(e) => {
                  e.isPropagationStopped();
                  navigate(PlatformPipelineRouteName);
                  setTargetClassName("style-platformPipeline-pipeline");
                  setAllShowStateToFalse();
                }}
              >
                Pipeline &gt;
              </button>
              <button
                onClick={(e) => {
                  e.isPropagationStopped();
                  navigate(PlatformPipelineRouteName);
                  setTargetClassName("style-platformPipeline-cmc");
                  setAllShowStateToFalse();
                }}
              >
                CMC &gt;
              </button>
              <button
                onClick={(e) => {
                  e.isPropagationStopped();
                  navigate(PlatformPipelineRouteName);
                  setTargetClassName("style-platformPipeline-publication");
                  setAllShowStateToFalse();
                }}
              >
                Publication &gt;
              </button>
            </div>
          </div>
          <div className="style-footer-contents-category-tile">
            {isWhichWindow === 2 ? (
              <div
                className="style-footer-contents-category-tile-drop"
                onClick={() => setIsShowCulture(!isShowCulture)}
              >
                <div>Culture & Career</div>
                <DownArrowAsset
                  className="style-footer-contents-category-tile-asset"
                  style={{
                    transform: isShowCulture
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                />
              </div>
            ) : (
              <div>Culture & Career</div>
            )}

            <div
              className="style-footer-contents-category-tile-button"
              id={
                isShowCulture ? "style-footer-category-tile-button-active" : ""
              }
              style={{ height: isShowCulture ? "96px" : null }}
            >
              <button
                onClick={(e) => {
                  e.isPropagationStopped();
                  navigate(CultureCareerRouteName);
                  setTargetClassName("style-cultureCareer-culture");
                  setAllShowStateToFalse();
                }}
              >
                Culture &gt;
              </button>
              <button
                onClick={(e) => {
                  e.isPropagationStopped();
                  navigate(CultureCareerRouteName);
                  setTargetClassName("style-cultureCareer-step");
                  setAllShowStateToFalse();
                }}
              >
                Career &gt;
              </button>
            </div>
          </div>
          <div className="style-footer-contents-category-tile">
            {isWhichWindow === 2 ? (
              <div
                className="style-footer-contents-category-tile-drop"
                onClick={() => setIsShowInvestor(!isShowInvestor)}
              >
                <div>Investor & Media</div>
                <DownArrowAsset
                  className="style-footer-contents-category-tile-asset"
                  style={{
                    transform: isShowInvestor
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                />
              </div>
            ) : (
              <div>Investor & Media</div>
            )}

            <div
              className="style-footer-contents-category-tile-button"
              id={
                isShowInvestor ? "style-footer-category-tile-button-active" : ""
              }
              style={{ height: isShowInvestor ? "96px" : null }}
            >
              <button
                onClick={(e) => {
                  e.isPropagationStopped();
                  navigate(InvestorMediaRouteName);
                  setTargetClassName("style-investorMedia-content");
                  setAllShowStateToFalse();
                }}
              >
                Investor &gt;
              </button>
              <button
                onClick={(e) => {
                  e.isPropagationStopped();
                  navigate(InvestorMediaRouteName);
                  setTargetClassName("style-investorMedia-media");
                  setAllShowStateToFalse();
                }}
              >
                News Media &gt;
              </button>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterPage;
