import React, { useEffect, useState } from "react";
import {
  NumberMonthToStringMonth,
  getHistoryList,
} from "../../functions/HistoryFunction.js";
import { ReactComponent as AboutContentsAsset } from "../../assets/AboutPage/AboutContentsAsset.svg";
import { Wrapper } from "@googlemaps/react-wrapper";
import MapComponent from "../../components/MapComponent.js";
import "../../styles/engStyles/AboutEngStyle.scss";

const AboutEngPage = ({ isWhichWindow }) => {
  const [historyArray, setHistoryArray] = useState([]);
  const [isHistoryArrayLoading, setIsHistoryArrayLoading] = useState(true);

  useEffect(() => {
    getHistoryList(setHistoryArray, "eng").then(() => {
      setIsHistoryArrayLoading(false);
    });
  }, [isHistoryArrayLoading]);

  return (
    <>
      <div className="style-about-title">
        <div className="style-eng-about-title-text">
          <span>About AAVATAR</span>
          {isWhichWindow === 0 ? (
            <span>
              Aavatar Therapeutics' mission is to contribute to solving
              humanity's health
              <br />
              problems by providing solutions for rare and intractable diseases
              based on <br />
              AAV platform technology.
            </span>
          ) : isWhichWindow === 1 ? (
            <span>
              Aavatar Therapeutics' mission is to contribute to <br />
              solving humanity's health problems by providing solutions <br />
              for rare and intractable diseases based on AAV platform
              technology.
            </span>
          ) : (
            <span>
              Aavatar Therapeutics' mission is to <br />
              contribute to solving humanity's health problems
              <br />
              by providing solutions for rare and intractable diseases <br />
              based on AAV platform technology.
            </span>
          )}
        </div>
      </div>

      <div className="style-eng-about-contents">
        {isWhichWindow === 1 || isWhichWindow === 2 ? (
          <>
            <span>Mission</span>
            <span>
              Avatar Therapeutics' mission is to <br />
              contribute to solving human health problems
              <br />
              by providing rare and incurable treatment solutions
              <br />
              {isWhichWindow === 2 ? (
                <b>based on AAV platform technology.</b>
              ) : (
                "based on AAV platform technology."
              )}
            </span>
          </>
        ) : null}
        <AboutContentsAsset id="style-about-contents-asset" />
        {isWhichWindow === 0 ? (
          <span>
            Aavatar Therapeutics' mission is to contribute to solving
            <br />
            humanity's health problems by providing solutions
            <br />
            for rare and intractable diseases based on AAV platform technology.
          </span>
        ) : null}
      </div>

      {isWhichWindow === 2 ? (
        <div className="style-about-history">
          <span id="style-eng-about-history-title">Corporate History</span>
          <div className="style-eng-about-history-background-top"></div>
          <div className="style-eng-about-history-contents">
            {historyArray.map((historyArrayValue) => (
              <div
                key={historyArrayValue.id}
                className="style-eng-about-history-contents-year"
              >
                {historyArrayValue.year}
                <hr />
                <div className="style-eng-about-history-centents-tile">
                  {Array.isArray(historyArrayValue.months)
                    ? historyArrayValue.months.map((monthArrayValue) => (
                        <div
                          key={monthArrayValue.id}
                          className="style-eng-about-history-contents-month"
                        >
                          <span>
                            {NumberMonthToStringMonth(
                              "eng",
                              monthArrayValue.month
                            )}
                          </span>
                          <div
                            className="style-eng-about-history-contents-month-contents"
                            dangerouslySetInnerHTML={{
                              __html: monthArrayValue.days,
                            }}
                          ></div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            ))}
            <div className="style-about-history-contents-blank"></div>
          </div>
          <div className="style-eng-about-history-background-bottom"></div>
        </div>
      ) : (
        <div className="style-about-history">
          <span id="style-eng-about-history-title">Corporate History</span>
          <div className="style-eng-about-history-background-top"></div>
          <div className="style-eng-about-history-background">
            <hr className="style-eng-about-history-background-hr" />
            <div className="style-eng-about-history-contents">
              {historyArray.map((historyArrayValue) => (
                <div
                  key={historyArrayValue.id}
                  className="style-eng-about-history-contents-year"
                >
                  <span className="style-eng-about-history-contents-year-title">
                    {historyArrayValue.year}
                  </span>
                  <div className="style-eng-about-history-contents-month">
                    {Array.isArray(historyArrayValue.months)
                      ? historyArrayValue.months.map((monthArrayValue) => (
                          <div
                            key={monthArrayValue.id}
                            className="style-eng-about-history-contents-month-tile"
                          >
                            <div>
                              {NumberMonthToStringMonth(
                                "eng",
                                monthArrayValue.month
                              )}
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: monthArrayValue.days,
                              }}
                            ></div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              ))}
              <div className="style-about-history-contents-blank"></div>
            </div>
          </div>
          <div className="style-eng-about-history-background-bottom"></div>
        </div>
      )}

      <div className="style-eng-about-contact">
        <span>Contact Us</span>
        <div className="style-eng-about-contact-contents">
          <Wrapper
            apiKey={process.env.REACT_APP_API_KEY}
            className="style-about-contact-map"
          >
            <MapComponent />
          </Wrapper>
          <div className="style-eng-about-contact-info">
            <div className="style-eng-about-contact-address">
              <span>Headquarter</span>

              <span>
                B-1022 540, Misa-daero,
                <br />
                Hanam-si, Gyeonggi-do,
                <br />
                Republic of Korea
              </span>
              {isWhichWindow === 2 ? <span>12925</span> : null}
            </div>

            <div className="style-eng-about-contact-address">
              <span>Biohub</span>

              <span>
                203, 117-3 Hoegi-ro,
                <br />
                Dongdaemun-gu, Seoul
              </span>
            </div>
            <div className="style-eng-about-contact-email">
              <span>E-mail</span>
              <span>information@aavatartps.com</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutEngPage;
