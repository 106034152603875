import React from "react";
import { useNavigate } from "react-router-dom";
import {
  PlatformPipelineRouteName,
  InvestorMediaRouteName,
} from "../../routes/RouteName";
import { ReactComponent as LogoMobileAsset } from "../../assets/LogoColorHorizontalAsset.svg";
import CarouselSliderComponent from "../../components/CarouselSliderComponent.js";
import "../../styles/engStyles/MainEngStyle.scss";

const MainEngPage = ({ isWhichWindow }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="style-main-title">
        <span>AAVATAR Therapeutics</span>
        <span>
          We are an innovative gene therapy company
          {isWhichWindow === 2 ? " " : <br />}
          that develops a next-generation intelligent gene therapy platform.
        </span>
      </div>

      <div className="style-eng-main-contents">
        {isWhichWindow === 0 ? (
          <>
            <span>Less but Better</span>
            <span>Paradigm shift to the era of gene therapy</span>
          </>
        ) : (
          <>
            <span>Paradigm shift to the era of gene therapy</span>
            <span>Less but Better</span>
          </>
        )}

        {isWhichWindow === 2 ? (
          <span>
            Avatar Therapeutics' mission is to <br />
            contribute to solving human health problems
            <br />
            by providing rare and incurable treatment solutions
            <br />
            <b>based on AAV platform technology.</b>
          </span>
        ) : (
          <span>
            Avatar Therapeutics' mission is to contribute to solving human
            health problems
            <br />
            by providing rare and incurable treatment solutions
            <br />
            <b>based on AAV platform technology.</b>
          </span>
        )}
        <div id="style-main-contents-asset">
          <div></div>
          <div id="style-eng-main-contents-asset-foreground" />
          <div></div>
        </div>
      </div>

      <div className="style-main-categories">
        <div onClick={() => navigate(PlatformPipelineRouteName)}>
          Platform & Pipeline
        </div>
        <div onClick={() => navigate(InvestorMediaRouteName)}>
          Investor & Media
        </div>
      </div>

      {isWhichWindow === 2 ? (
        <LogoMobileAsset className="style-main-logo" />
      ) : null}

      <div className="style-main-slider">
        <CarouselSliderComponent isWhichWindow={isWhichWindow} />
        <div id="style-main-slider-shadow"></div>
      </div>

      <div className="style-main-corporation">
        <span>in corporation with...</span>
        <div id="style-main-corporation-asset"></div>
      </div>
    </>
  );
};

export default MainEngPage;
