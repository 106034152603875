import { readMediaListDocument } from "../repositories/MediaRepository";

export const getMediaList = async (setMediaList) => {
  const mediaList = await readMediaListDocument();
  const mediaArray = mediaList.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  setMediaList(mediaArray);
};

const maxMediaPublisherTextLength = 35;

export const truncateMediaPublisherText = (mediaPublisherTextData) => {
  const truncatedText =
    mediaPublisherTextData.length > maxMediaPublisherTextLength
      ? mediaPublisherTextData.slice(0, maxMediaPublisherTextLength) + "..."
      : mediaPublisherTextData;
  return truncatedText;
};

const maxMediaTitleTextLength = 50;

export const truncateMediaTitleText = (mediaTitleTextData) => {
  const truncatedText =
    mediaTitleTextData.length > maxMediaTitleTextLength
      ? mediaTitleTextData.slice(0, maxMediaTitleTextLength) + "..."
      : mediaTitleTextData;
  return truncatedText;
};

const maxMediaLinkTextLength = 62;

export const truncateMediaLinkText = (mediaLinkTextData) => {
  const truncatedText =
    mediaLinkTextData.length > maxMediaLinkTextLength
      ? mediaLinkTextData.slice(0, maxMediaLinkTextLength) + "..."
      : mediaLinkTextData;
  return truncatedText;
};
