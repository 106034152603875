import React, { useEffect, useState } from "react";
import "../../styles/JobOpeningStyle.scss";
import { useLocation } from "react-router-dom";
import { convertTimestampToDate } from "../../functions/JobOpeningFunction";

const JobOpeningEngPage = ({ isWhichWindow }) => {
  const location = useLocation();
  const [jobData] = useState(location.state.data);
  const [jobDepartment, setJobDepartment] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobBody, setJobBody] = useState("");
  const [jobDateStart, setJobDateStart] = useState("");
  const [jobDateEnd, setJobDateEnd] = useState("");
  const [jobState, setJobState] = useState("");
  const [isJobLoading, setIsJobLoading] = useState(true);

  useEffect(() => {
    if (jobData !== null) {
      setJobDepartment(jobData.department);
      setJobTitle(jobData.title);
      setJobBody(jobData.body);
      setJobDateStart(convertTimestampToDate(jobData.dateStart));
      setJobDateEnd(convertTimestampToDate(jobData.dateEnd));
      setJobState(jobData.state);
      setIsJobLoading(false);
    }
  }, [isJobLoading, jobData]);

  return (
    <>
      <div className="style-cultureCareer-title">
        <div className="style-eng-cultureCareer-title-text">
          <span>Culture & Career</span>
          <span>
            Aavatar respects each member and pursues the{" "}
            {isWhichWindow === 2 ? <br /> : null}happy development of its
            members {isWhichWindow === 2 ? " " : <br />}
            and "maximum {isWhichWindow === 2 ? <br /> : null}efficiency within
            maximum freedom."
          </span>
        </div>
      </div>

      <div className="style-job">
        <div className="style-eng-job-title">
          <div>Job Announcement</div>
          <div>{jobTitle}</div>
        </div>

        <div className="style-eng-job-subTitle">
          <div className="style-eng-job-subTitle-department">
            <span>Department</span>
            <span>{jobDepartment}</span>
          </div>
          <div className="style-eng-job-subTitle-date">
            <span>Application Period</span>
            <span>
              {jobDateStart}~{jobDateEnd}
            </span>
          </div>
          <div className="style-eng-job-subTitle-state">
            <span>Application Period</span>
            <span>{jobState ? "Accepting" : "Closed"}</span>
          </div>
        </div>
        <hr className="style-job-divider" />
        <div>{jobBody}</div>
      </div>
    </>
  );
};

export default JobOpeningEngPage;
