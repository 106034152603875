import React, { useEffect, useState } from "react";
import { getPublicationList } from "../functions/PublicationFunction";
import PipelineChartComponent from "../components/PipelineChartComponent";
import PageNavigationComponent from "../components/PageNavigationComponent";
import { ReactComponent as PlatformContent1Asset } from "../assets/PlatformPipelinePage/PlatformContent1Asset.svg";
import { ReactComponent as PlatformContent1TabletAsset } from "../assets/PlatformPipelinePage/PlatformContent1TabletAsset.svg";
import { ReactComponent as PlatformContent1MobileAsset } from "../assets/PlatformPipelinePage/PlatformContent1MobileAsset.svg";
import { ReactComponent as PlatformContent2ForegroundAsset } from "../assets/PlatformPipelinePage/PlatformContent2ForegroundAsset.svg";
import "../styles/PlatformPipelineStyle.scss";

const PlatformPipelinePage = ({ isWhichWindow }) => {
  const [isWhichCMC, setIsWhichCMC] = useState(0);

  const [publicationArray, setPublicationArray] = useState([]);
  const [isPublicationArrayLoading, setIsPublicationArrayLoading] =
    useState(true);
  const [publicationPage, setPublicationPage] = useState(1);
  const publicationPageLimit = 3;
  const publicationPageOffset = (publicationPage - 1) * publicationPageLimit;

  useEffect(() => {
    getPublicationList(setPublicationArray).then(() => {
      setIsPublicationArrayLoading(false);
    });
  }, [isPublicationArrayLoading]);

  return (
    <>
      <div className="style-platformPipeline-title">
        <div className="style-platformPipeline-title-text">
          <span>Platform & Pipeline</span>
        </div>
      </div>

      <div className="style-platformPipeline-aavplatform">
        <span>AAV 플랫폼</span>
        {isWhichWindow === 2 ? (
          <span>
            아바타테라퓨틱스의 <b>AAV 엔지니어링 플랫폼</b>은
            <br />
            Machine Learning을 바탕으로 <br />
            새로운 AAV를 효율적으로 창출해낼 수 있습니다.
            <br />
            <br />
            이러한 <b>새로운 AAV</b>는 저용량으로도 높은 표적 전달 및 <br />
            BBB 침투를 할 수 있어서
            <br />
            전통적인 <b>유전자 전달 벡터의 문제를 해결</b>할 것입니다.
          </span>
        ) : (
          <span>
            아바타테라퓨틱스의 <b>AAV 엔지니어링 플랫폼</b>은
            <br />
            Machine Learning을 바탕으로 새로운 AAV를 효율적으로 창출해낼 수
            있습니다.
            <br />
            이러한 <b>새로운 AAV</b>는 저용량으로도 높은 표적 전달 및 BBB 침투를
            할 수 있어서
            <br />
            전통적인 <b>유전자 전달 벡터의 문제를 해결</b>할 것입니다.
          </span>
        )}
        {isWhichWindow === 0 ? (
          <PlatformContent1Asset id="style-platformPipeline-content1" />
        ) : isWhichWindow === 1 ? (
          <PlatformContent1TabletAsset id="style-platformPipeline-content1" />
        ) : (
          <PlatformContent1MobileAsset id="style-platformPipeline-content1" />
        )}

        <div id="style-platformPipeline-content2">
          <div id="style-platformPipeline-content2-background"></div>
          <div>Less but Better</div>
          <div>
            AAV Engineering, Common Disease, Protein Engineering,
            <br />
            Rare Disease, Genome Editing
          </div>
          <PlatformContent2ForegroundAsset id="style-platformPipeline-content2-asset" />
          {isWhichWindow === 2 ? <span>Pipeline</span> : null}
        </div>
      </div>

      <div className="style-platformPipeline-pipeline">
        {isWhichWindow !== 2 ? <span>Pipeline</span> : null}
        <PipelineChartComponent isWhichWindow={isWhichWindow} />
      </div>

      <div className="style-platformPipeline-cmc">
        <span>CMC</span>
        {isWhichWindow === 2 ? (
          <span>
            아바타테라퓨틱스의 CMC본부는 DOE기반으로
            <br />
            AAV를 저비용, 고순도, 높은 수율로 생산할 수 있습니다.
            <br />
            또한 자체적인 생산 내재화를 통하여
            <br />
            빠른 연구 피드백 능력을 가지고 있습니다.
          </span>
        ) : (
          <span>
            아바타테라퓨틱스의 CMC본부는
            <br />
            DOE기반으로 AAV를 저비용, 고순도, 높은 수율로 생산할 수 있습니다.
            <br />
            또한 자체적인 생산 내재화를 통하여 빠른 연구 피드백 능력을 가지고
            있습니다.
          </span>
        )}

        {isWhichWindow === 2 ? (
          <div className="style-platformPipeline-cmc-content">
            <div className="style-platformPipeline-cmc-navigation">
              <div
                id={isWhichCMC === 0 ? "style-cmc-navigation-select" : ""}
                onClick={() => setIsWhichCMC(0)}
              >
                Upstream
              </div>
              <div
                id={isWhichCMC === 1 ? "style-cmc-navigation-select" : ""}
                onClick={() => setIsWhichCMC(1)}
              >
                Downstream
              </div>
            </div>
            <div
              className={
                isWhichCMC === 0
                  ? "style-platformPipeline-cmc-content-upstream"
                  : "style-platformPipeline-cmc-content-downstream"
              }
            ></div>
          </div>
        ) : (
          <div className="style-platformPipeline-cmc-content">
            <div className="style-platformPipeline-cmc-content-upstream">
              <div>Upstream</div>
              <div></div>
            </div>
            <hr className="style-platformPipeline-cmc-content-hr" />
            <div className="style-platformPipeline-cmc-content-downstream">
              <div>Downstream</div>
              <div></div>
            </div>
          </div>
        )}
      </div>

      <div className="style-platformPipeline-publication">
        <span>Publication</span>
        {isWhichWindow === 2 ? (
          <div className="style-platformPipeline-publication-board">
            {publicationArray
              .slice(
                publicationPageOffset,
                publicationPageOffset + publicationPageLimit
              )
              .map((publicationArrayValue) => (
                <div
                  className="style-platformPipeline-publication-board-tile"
                  key={publicationArrayValue.id}
                >
                  <div className="style-platformPipeline-publication-board-tile-title">
                    <div>
                      <span>
                        {publicationArray.indexOf(publicationArrayValue) + 1}
                      </span>
                    </div>
                    <div>{publicationArrayValue.year}</div>
                  </div>
                  <div className="style-platformPipeline-publication-board-tile-body">
                    {publicationArrayValue.title.replace(/<br\/>/g, " ")}
                  </div>
                  <div className="style-platformPipeline-publication-board-tile-tag">
                    <div>{publicationArrayValue.journal}</div>
                    <hr />
                    <div>등급: {publicationArrayValue.database}</div>
                  </div>
                  <div className="style-platformPipeline-publication-board-tile-bottom">
                    Impact factor: {publicationArrayValue.impactFactor}
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="style-platformPipeline-publication-board">
            <div
              className="style-platformPipeline-publication-board-tile"
              id="style-platformPipeline-publication-board-title"
            >
              <div>순번</div>
              <div>연도</div>
              <div>제목</div>
              <div>게재지</div>
              <div>등급</div>
              <div>Impact factor</div>
            </div>

            {publicationArray
              .slice(
                publicationPageOffset,
                publicationPageOffset + publicationPageLimit
              )
              .map((publicationArrayValue) => (
                <div
                  className="style-platformPipeline-publication-board-tile"
                  key={publicationArrayValue.id}
                >
                  <div>
                    {publicationArray.indexOf(publicationArrayValue) + 1}
                  </div>
                  <div>{publicationArrayValue.year}</div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: publicationArrayValue.title,
                    }}
                  ></div>
                  <div>{publicationArrayValue.journal}</div>
                  <div>{publicationArrayValue.database}</div>
                  <div>{publicationArrayValue.impactFactor}</div>
                </div>
              ))}
          </div>
        )}
      </div>
      <div className="style-platformPipeline-publication-navigation">
        <PageNavigationComponent
          dataLength={publicationArray.length}
          pageLimit={publicationPageLimit}
          page={publicationPage}
          setPage={setPublicationPage}
        />
      </div>
    </>
  );
};

export default PlatformPipelinePage;
