import { dbService } from "../fbase";
import { MediaModelConverter } from "../models/MediaModel";

export const readMediaListDocument = async () => {
  const mediaListModel = await dbService
    .collection("Medias")
    .orderBy("dateUpload", "desc")
    .get();
  return mediaListModel;
};

export const readMediaDocument = async (id) => {
  let mediaModel;
  await dbService
    .collection("Medias")
    .doc(id)
    .withConverter(MediaModelConverter)
    .get()
    .then((doc) => {
      if (doc.exists) {
        mediaModel = doc.data().id;
      }
    });

  return mediaModel;
};
