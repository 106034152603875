import React, { useEffect, useState } from "react";
import { getMediaList } from "../../functions/MediaFunction";
import MediaCardComponent from "../../components/MediaCardComponent";
import PageNavigationComponent from "../../components/PageNavigationComponent";
import "../../styles/engStyles/InvestorMediaEngStyle.scss";

const InvestorMediaEngPage = ({ isWhichWindow }) => {
  const [mediaArray, setMediaArray] = useState([]);
  const [isMediaArrayLoading, setIsMediaArrayLoading] = useState(true);
  const [mediaPage, setMediaPage] = useState(1);
  const mediaPageLimit = 4;
  const mediaPageOffset = (mediaPage - 1) * mediaPageLimit;

  useEffect(() => {
    getMediaList(setMediaArray).then(() => {
      setIsMediaArrayLoading(false);
    });
  }, [isMediaArrayLoading]);

  return (
    <>
      <div className="style-investorMedia-title">
        <div className="style-eng-investorMedia-title-text">
          <span>Investor & Media</span>
          {isWhichWindow === 2 ? (
            <span>
              Aavatar Therapeutics aims to advance gene therapy
              <br /> technology and, based on this, seeks to pursue the health
              <br />
              and happiness of humanity.
            </span>
          ) : (
            <span>
              Aavatar Therapeutics aims to advance gene therapy technology and,
              <br />
              based on this, seeks to pursue the health and happiness of
              humanity.
            </span>
          )}
        </div>
      </div>

      <div className="style-investorMedia-content">
        <span>Investor</span>
        <div className="style-investorMedia-content-asset"></div>
      </div>

      <div className="style-investorMedia-media">
        <span>Media</span>
        <div className="style-investorMedia-media-contents">
          <div className="style-investorMedia-media-contents-board">
            {isWhichWindow === 2
              ? mediaArray
                  .slice((mediaPage - 1) * 3, (mediaPage - 1) * 3 + 3)
                  .map((mediaArrayValue) => (
                    <MediaCardComponent
                      mediaObject={mediaArrayValue}
                      key={mediaArrayValue.id}
                    />
                  ))
              : mediaArray
                  .slice(mediaPageOffset, mediaPageOffset + mediaPageLimit)
                  .map((mediaArrayValue) => (
                    <MediaCardComponent
                      mediaObject={mediaArrayValue}
                      key={mediaArrayValue.id}
                    />
                  ))}
          </div>
          <div className="style-investorMedia-media-contents-navigation">
            {isWhichWindow === 2 ? (
              <PageNavigationComponent
                dataLength={mediaArray.length}
                pageLimit={3}
                page={mediaPage}
                setPage={setMediaPage}
              />
            ) : (
              <PageNavigationComponent
                dataLength={mediaArray.length}
                pageLimit={mediaPageLimit}
                page={mediaPage}
                setPage={setMediaPage}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestorMediaEngPage;
